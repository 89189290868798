import { receptionPointsTemplate } from "./reception-points-template";
import PointsBase from "../points-base";
class ReceptionPoints extends PointsBase {
    constructor() {
        const container = document.querySelector(".reception-points");
        const mapContainer = document.querySelector(".reception-points--map-wrapper");
        super(container, mapContainer, receptionPointsTemplate);
    }
}
export default ReceptionPoints;
