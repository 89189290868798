import anime from 'animejs/lib/anime.es';
class HoverMorphSvg {
    constructor(selector) {
        const animatedSvg = Array.from(document.querySelectorAll(selector));
        animatedSvg.forEach((item) => {
            const startPaths = Array.from(item.querySelectorAll('[class^="start__"]'));
            const startPos = startPaths.map((item) => item.getAttribute('d'));
            const endPaths = Array.from(item.querySelectorAll('[class^="end__"]'));
            const endPos = endPaths.map((item) => item.getAttribute('d'));
            if (startPos.length !== endPos.length)
                return;
            item.onmouseenter = () => {
                startPaths.forEach((target, i) => this.animate(target, endPos[i]));
            };
            item.onmouseleave = () => {
                startPaths.forEach((target, i) => this.animate(target, startPos[i]));
            };
        });
    }
    animate(target, path) {
        anime({
            targets: target,
            d: [{ value: path }],
            duration: 150,
            direction: 'normal',
            easing: 'linear',
            elasticity: 100
        });
    }
}
export default HoverMorphSvg;
