export default function splitText(container) {
    let s = container.innerText.split(/\s+/);
    let h = { curr: 0, prev: 0 }, parts = [], word, back = [], strBack = "";
    while (s.length) {
        word = s.shift();
        back.push(word);
        strBack = back.join(" ");
        container.innerHTML = strBack.replace(/&&/g, " ");
        h.curr = container.offsetHeight;
        if (h.curr !== h.prev) {
            parts.push([]);
            h.prev = h.curr;
        }
        parts[parts.length - 1].push(word.replace(/&&/g, " "));
    }
    parts = parts.map((e, i) => '<span class="text--line text--line-' +
        i +
        '"><span class="animated-text" style="transform: translateY(100%)">' +
        e.join(" ") +
        "</span></span>");
    container.innerHTML = parts.join("\n");
}
