class ItcCollapse {
    constructor(target, duration = 350, toggle = false, classBtn = null) {
        this._target = target;
        this._duration = duration;
        this._classBtn = classBtn;
        if (toggle)
            if (this._classBtn) {
                this._target.parentNode.querySelector(this._classBtn).onclick =
                    () => {
                        this.toggle();
                    };
            }
            else {
                this._target.parentNode.onclick = () => {
                    this.toggle();
                };
            }
    }
    show() {
        const el = this._target;
        if (el.classList.contains("collapsing") ||
            el.classList.contains("show")) {
            return;
        }
        el.classList.remove("collapse");
        const height = el.offsetHeight;
        el.style["height"] = 0;
        el.style["margin-top"] = 0;
        el.style["margin-bottom"] = 0;
        el.style["overflow"] = "hidden";
        el.style["transition"] = `all ${this._duration}ms ease`;
        el.classList.add("collapsing");
        el.offsetHeight;
        el.style["height"] = `${height}px`;
        el.style["margin-top"] = window.getComputedStyle(el).marginTop;
        el.style["margin-bottom"] = window.getComputedStyle(el).marginBottom;
        el.parentNode.classList.add("_opened");
        window.setTimeout(() => {
            el.classList.remove("collapsing");
            el.classList.add("collapse");
            el.classList.add("show");
            el.style["height"] = "";
            el.style["margin-top"] = "";
            el.style["margin-bottom"] = "";
            el.style["transition"] = "";
            el.style["overflow"] = "";
        }, this._duration);
    }
    hide() {
        const el = this._target;
        if (el.classList.contains("collapsing") ||
            !el.classList.contains("show")) {
            return;
        }
        el.style["height"] = `${el.offsetHeight}px`;
        el.offsetHeight;
        el.style["height"] = 0;
        el.style["margin-top"] = 0;
        el.style["margin-bottom"] = 0;
        el.style["overflow"] = "hidden";
        el.style["transition"] = `all ${this._duration}ms ease`;
        el.classList.remove("collapse");
        el.classList.remove("show");
        el.classList.add("collapsing");
        el.parentNode.classList.remove("_opened");
        window.setTimeout(() => {
            el.classList.remove("collapsing");
            el.classList.add("collapse");
            el.style["height"] = "";
            el.style["margin-top"] = "";
            el.style["margin-bottom"] = "";
            el.style["transition"] = "";
            el.style["overflow"] = "";
        }, this._duration);
    }
    toggle() {
        this._target.classList.contains("show") ? this.hide() : this.show();
    }
}
export default ItcCollapse;
