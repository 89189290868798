import Swiper from "swiper";
class NewsSlider {
    constructor() {
        new Swiper('.news--slider', {
            // modules: [Autoplay],
            loop: false,
            grabCursor: true,
            slidesPerView: 'auto',
            spaceBetween: 12,
            /*autoplay: {
                delay: 4000,
                disableOnInteraction: false,
            }*/
        });
    }
}
export default NewsSlider;
