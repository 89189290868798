class Popup {
    constructor(selector) {
        this.element = selector;
        if (this.element === null) {
            return;
        }
        Array.from(this.element.querySelectorAll("[data-dissmiss]")).forEach((button) => {
            button.addEventListener("click", this.close.bind(this));
        });
    }
    open() {
        let scrollWidth = outerWidth - document.body.clientWidth;
        this.element.classList.add("popup_opened");
        if (scrollWidth > 0)
            document.body.style.paddingRight = scrollWidth + "px";
        document.body.classList.add("overflow-hidden");
    }
    close() {
        this.element.classList.remove("popup_opened");
        document.body.style.paddingRight = "";
        document.body.classList.remove("overflow-hidden");
    }
    static closeAll() {
        Array.from(document.querySelectorAll(".popup.popup_opened")).forEach((elem) => {
            elem.classList.remove("popup_opened");
            document.body.classList.remove("overflow-hidden");
        });
    }
}
export default Popup;
