class ReceptionPointsTemplate {
    constructor() { }
    renderPointItem(item) {
        const itemContainer = document.createElement("div");
        const route = `https://yandex.ru/maps/?rtext=~${item.LAN_LAT[1]},${item.LAN_LAT[0]}`;
        const isSale = item.SALE !== false;
        const saleBadge = isSale
            ? '<div class="reception-points--points-item--badge">Акция</div>'
            : "";
        const saleBlock = isSale
            ? `<div class="reception-points--points-item--sale">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.3333 6L2 10L9.33333 10.6667L9.33333 14L18 10L11.3333 9.33333L11.3333 6Z" fill="#BCFB00"></path></svg>
                    <span>${item.SALE}</span>
                </div>`
            : "";
        itemContainer.innerHTML = `<div class="reception-points--points-item--top">
                <div class="radio-button">
                    <input data-ss="ss" type="radio" name="points" id="point-page-${item.ID}" data-point-id="${item.ID}" value="${item.ID}">
                    <label for="point-page-${item.ID}">
                        <span class="state-unchecked">Выбрать</span>
                        <span class="state-checked">Пункт выбран</span>
                    </label>
                </div>
                ${saleBadge}
            </div>
            <div class="reception-points--points-item--title">${item.NAME}</div>

            <div class="reception-points--points-item--content">
                <div class="reception-points--points-item--desc">${item.ADDRESS_TEXT_VALUE}</div>
                ${saleBlock}
                <div class="reception-points--points-item--work-time">${item.WORK_TIME_VALUE}</div>
                <div class="reception-points--points-item--bottom">
                    <a class="reception-points--points-item--phone" href="tel:${item.PHONE_VALUE}">${item.PHONE_VALUE}</a>
                    <a class="reception-points--points-item--route" target="_blank" href="${route}">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                            <path d="M4 4.5H11C12.1046 4.5 13 5.39543 13 6.5V6.5C13 7.60457 12.1046 8.5 11 8.5H5C3.89543 8.5 3 9.39543 3 10.5V10.5C3 11.6046 3.89543 12.5 5 12.5H12" stroke="#434E4F" stroke-width="1.5" stroke-linecap="round"></path>
                            <circle cx="1.25" cy="1.25" r="1.25" transform="matrix(-1 0 0 1 13.5 11.25)" fill="#434E4F"></circle>
                            <circle cx="1.25" cy="1.25" r="1.25" transform="matrix(-1 0 0 1 5 3.25)" fill="#434E4F"></circle>
                        </svg>
                        <span>Построить маршрут</span>
                    </a>
                </div>
            </div>`;
        itemContainer.classList.add("reception-points--points-item");
        itemContainer.classList.add("js-point-item");
        return itemContainer;
    }
}
export const receptionPointsTemplate = new ReceptionPointsTemplate();
