import Swiper from "swiper";
import { Navigation } from "swiper/modules";
class SampleSlider {
    constructor() {
        new Swiper('.sample-page--slider', {
            modules: [Navigation],
            loop: false,
            speed: 550,
            slidesPerView: 'auto',
            spaceBetween: 12,
            grabCursor: true,
            navigation: {
                nextEl: '.button-slider__next',
                prevEl: '.button-slider__prev',
                disabledClass: 'disabled',
            },
            breakpoints: {
                768: {
                    slidesPerView: 1,
                    spaceBetween: 25
                }
            }
        });
    }
}
export default SampleSlider;
