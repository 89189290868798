import preloader from "./components/preloader";
import { heroSlider } from "./components/sliders/hero/hero-slider";
import Marque from "./components/sliders/marque";
import AdvantagesSlider from "./components/sliders/advantages-slider";
import ReviewsSlider from "./components/sliders/reviews-slider";
import NewsSlider from "./components/sliders/news-slider";
import OffersSlider from "./components/sliders/offers-slider";
import AchievementsSlider from "./components/sliders/achievements-slider";
import ValuesSlider from "./components/sliders/values-slider";
import NewsDetailSlider from "./components/sliders/news-detail-slider";
import SampleSlider from "./components/sliders/sample-slider";
import HoverMorphSvg from "./components/hover-morph-svg";
import ItcCollapse from "./components/collapse-v2";
import "./components/map/main-map";
import "./components/search";
import "./components/input-file-multiply";
import "./components/tooltip";
import ReceptionPoints from "./components/reception-points/reception-points";
import PointsPopup from "./components/points-popup/points-popup";
import Popup from "./components/popup";
import { Fancybox } from "@fancyapps/ui";
import Calculator from "./components/calculator/calculator";
import PriceList from "./components/calculator/price-list";
import Forms from "./utils/forms";
const isHomePage = document.body.classList.contains("home-page");
function onEntry(entry) {
    entry.forEach((change) => {
        if (change.isIntersecting) {
            change.target.classList.add("_anim");
        }
    });
}
let options = { threshold: [0.3] };
let observer = new IntersectionObserver(onEntry, options);
let elements = Array.from(document.querySelectorAll(".steps--items, .reviews__items--item, .cases__items--item, .request--inner, .news--item, .contact-us--block, .steps--inner button, .section--title, .section--desc, .services--item, .advantages--item, .advantages--inner, .price--block-table, .price--request, .price-up--inner, .price-up__title, .price-up__item, .process-info--img, .process-info__text, .process-dismantling--inner, .process-dismantling__item, .quality-control--inner, .reviews--item:nth-child(n+2), .pagination, .section--button, .our-offers--slider-container, .vacancies--item, .vacancies-not-found--left, .vacancies-not-found--right, .achievements--slider-container, .about-text__item, .our-values--slider-container, .news-detail__subtitle, .sample-page__subtitle, .region-partners--item, .leadership--inner, .leadership--item"));
for (let elm of elements) {
    observer.observe(elm);
}
preloader.start();
document.addEventListener("DOMContentLoaded", () => {
    new Calculator();
    new PriceList();
    new Forms();
    setTimeout(() => {
        preloader.end();
        document.body.classList.add("loaded");
        heroSlider.start();
        new Marque(".line-prices--inner-wrapper");
        new Marque(".line-prices__with-action .line-prices--inner-wrapper");
        new Marque(".partners--inner .partners--list-wrapper", 1);
        new HoverMorphSvg(".advantages--item, .services--item");
        new AdvantagesSlider();
        new ReviewsSlider();
        new NewsSlider();
        new OffersSlider();
        new AchievementsSlider();
        new ValuesSlider();
        new NewsDetailSlider();
        new SampleSlider();
        new ReceptionPoints();
        new PointsPopup();
        const popupCallback = new Popup(document.querySelector(".popup_callback"));
        const callbackButton = document.querySelector(".header-main__call");
        if (callbackButton)
            callbackButton.onclick = () => popupCallback.open();
        const callbackButtons = document.querySelectorAll(".js-callback-btn");
        callbackButtons.forEach((btn) => {
            btn.onclick = () => popupCallback.open();
        });
        const popupReview = new Popup(document.querySelector(".popup_review"));
        const reviewButtons = Array.from(document.querySelectorAll(".js-review-btn"));
        reviewButtons.forEach((btn) => {
            btn.onclick = () => popupReview.open();
        });
        const popupResponseJob = new Popup(document.querySelector(".popup_response-job"));
        const responseJobButtons = Array.from(document.querySelectorAll(".js-response-job-btn"));
        responseJobButtons.forEach((btn) => {
            btn.onclick = () => {
                document.querySelector(".popup_response-job .vacancy-name").textContent = btn.dataset.name;
                document.querySelector(".popup_response-job input[name=city]").value = btn.dataset.city;
                document.querySelector(".popup_response-job input[name=vacancyName]").value = btn.dataset.name;
                document.querySelector(".popup_response-job input[name=vacancyId]").value = btn.dataset.id;
                popupResponseJob.open();
            };
        });
    }, 1000);
    Array.from(document.querySelectorAll(".header--nav--item_parent ul")).forEach((item) => new ItcCollapse(item, 350, true));
    Array.from(document.querySelectorAll(".header--nav--item_parent div")).forEach((item) => new ItcCollapse(item, 350, true));
    Array.from(document.querySelectorAll(".faq .faq--answer")).forEach((item) => new ItcCollapse(item, 350, true, ".faq--title"));
    Array.from(document.querySelectorAll(".vacancies--item .vacancies--body")).forEach((item) => new ItcCollapse(item, 350, true, ".vacancies--top"));
    document
        .querySelector(".header-main__menu-btn")
        .addEventListener("click", () => {
        const body = document.body;
        if (body.classList.contains("sidenav-opened"))
            body.classList.remove("sidenav-opened");
        else
            body.classList.add("sidenav-opened");
    });
    Fancybox.bind("[data-fancybox]", {
    // Your custom options
    });
});
