import PointsMap from "./map/points-map";
class PointsBase {
    constructor(container, mapContainer, template) {
        if (!mapContainer) {
            return;
        }
        this.template = template;
        this.container = container;
        this.mapContainer = mapContainer;
        /* init map */
        this.pointsMap = new PointsMap(this.mapContainer, {
            coordinates: this.getPoints(),
            start: this.getCurrentPointLanLat(),
            onMarkerClick: (id) => {
                this.markerSelected(id);
            }
        });
        this.citiId = this.getCurrentCityId();
        this.setCity();
        this.setPoints();
        this.pointSelected(this.getCurrentPoint());
        /* Tabs */
        const buttonTabs = Array.from(document.querySelectorAll(".button-tabs button"));
        buttonTabs.forEach((item) => {
            item.onclick = () => {
                buttonTabs.forEach((btn) => {
                    const target = document.getElementById(btn.dataset.target);
                    target.classList.remove("d-flex");
                    target.classList.add("d-none");
                    btn.classList.remove("active");
                });
                const target = document.getElementById(item.dataset.target);
                target.classList.remove("d-none");
                target.classList.add("d-flex");
                item.classList.add("active");
                this.pointsMap.map.update();
            };
        });
    }
    setPoints() {
        const itemsContainer = this.container.querySelector(".js-point-items");
        itemsContainer.innerHTML = "";
        this.getPointsByCityId(this.citiId).forEach((data) => {
            const htmlItem = this.template.renderPointItem(data);
            // @ts-ignore
            const inputItem = htmlItem.querySelector('input[name="points"], input[name="points_popup"]');
            console.log(inputItem, itemsContainer, "input");
            inputItem.onchange = (el) => {
                const input = el.target;
                this.pointSelected(input.value);
            };
            itemsContainer.appendChild(htmlItem);
        });
    }
    setCity() {
        const select = this.container.querySelector("[name='city']");
        select.innerHTML = "";
        Object.entries(this.getCities()).forEach(([key, city]) => {
            const option = document.createElement("option");
            //@ts-ignore
            option.value = String(city.ID);
            //@ts-ignore
            option.innerText = city.NAME;
            //@ts-ignore
            if (this.citiId == city.ID) {
                option.selected = true;
            }
            select.appendChild(option);
        });
        select.onchange = () => {
            this.citiId = select.value;
            this.setPoints();
        };
    }
    markerSelected(id) {
        this.citiId = this.getPointById(id)["LOCALITIES_ID"];
        this.setCity();
        this.setPoints();
        this.pointSelected(id);
    }
    pointSelected(id) {
        Array.from(this.container.querySelectorAll(".js-point-item")).forEach((i) => i.classList.remove("opened"));
        const input = this.container.querySelector(`[data-point-id="${id}"]`);
        input.checked = true;
        input.setAttribute("checked", "checked");
        const parent = input.parentNode.parentNode.parentNode;
        parent.classList.add("opened");
        this.pointsMap.setLocation({
            center: this.getPointById(id).LAN_LAT,
            zoom: 14,
            duration: 1000
        });
        Array.from(this.container.querySelectorAll(`[data-pin-id="${id}"]`)).forEach((item) => {
            Array.from(this.container.querySelectorAll(".pin-container")).forEach((pin) => pin.classList.remove("selected"));
            item.classList.add("selected");
        });
    }
    getPointById(id) {
        return this.getPoints().filter((items) => items.ID == id)[0];
    }
    getPointsByCityId(id) {
        return this.getPoints().filter((items) => items.LOCALITIES_ID === id);
    }
    getPoints() {
        //from <head> script
        //@ts-ignore
        return POINTS;
    }
    getCities() {
        //from <head> script
        //@ts-ignore
        return CITIES;
    }
    getCurrentPoint() {
        return this.getPoints().filter((items) => items.SELECTED === true)[0]["ID"];
    }
    getCurrentPointLanLat() {
        return this.getPoints().filter((items) => items.SELECTED === true)[0]["LAN_LAT"];
    }
    getCurrentCityId() {
        return this.getCities().filter((items) => items.SELECTED === true)[0]["ID"];
    }
}
export default PointsBase;
