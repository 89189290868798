class Search {
    constructor() {
        this.resultsContainer = document.querySelector(".header--search-results");
        if (!this.resultsContainer)
            return;
        /* Клик по лупе */
        document
            .querySelector(".header--nav--search-button")
            .addEventListener("click", () => {
            this.toggle();
        });
        /* Клик по крестику */
        Array.from(document.querySelectorAll(".search-input--clean")).forEach((clean) => clean.addEventListener("click", (e) => {
            e.preventDefault();
            clean.parentNode.querySelector("input").value = "";
            // this.hideResults();
        }));
        /* Клик "закрыть" */
        document
            .querySelector(".header--search-close")
            .addEventListener("click", () => this.close());
        /* Input keyup */
        /* document
            .querySelector<HTMLImageElement>(".header--search")
            .addEventListener("keyup", (e) => {
                const target = e.target as HTMLInputElement;
                if (target.value.length >= 2) {
                    this.showResults();
                } else {
                    this.hideResults();
                }
            });*/
        /* Click result */
        /* Array.from(
            document.querySelectorAll<HTMLButtonElement>(
                ".header--search-results-item"
            )
        ).forEach((result) =>
            result.addEventListener("click", (e) => {
                console.log(result.innerText);
                result.parentNode.parentNode.parentNode.querySelector(
                    "input"
                ).value = result.innerText;
                //this.hideResults();
            })
        );*/
    }
    /* showResults() {
        this.resultsContainer.classList.add("header--search-results_opened");
    }

    hideResults() {
        this.resultsContainer.classList.remove("header--search-results_opened");
    }*/
    toggle() {
        if (document.body.classList.contains("search-opened"))
            document.body.classList.remove("search-opened");
        else
            document.body.classList.add("search-opened");
    }
    close() {
        // this.hideResults();
        document.body.classList.remove("search-opened");
    }
}
document.addEventListener("DOMContentLoaded", () => {
    new Search();
});
