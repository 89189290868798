let calcMock = {
    priceList: [
        {
            id: 1441,
            categoryId: 125,
            categoryName: "Черный металл",
            name: "3А",
            desc: "Стальной габаритный лом",
            pricePerTon: 12000,
            pricePerKilogram: 12,
            salePricePerTon: 0,
            salePricePerKilogram: 0,
            currency: "RUB" // KZT
        },
        {
            id: 1512,
            categoryId: 125,
            categoryName: "Черный металл",
            name: "5А",
            desc: "Стальной негабаритный лом",
            pricePerTon: 13000,
            pricePerKilogram: 13,
            salePricePerTon: 11000,
            salePricePerKilogram: 11,
            currency: "RUB" // KZT
        },
        {
            id: 1452,
            categoryId: 125,
            categoryName: "Черный металл",
            name: "12А",
            desc: "Стальной лом",
            pricePerTon: 11000,
            pricePerKilogram: 11,
            salePricePerTon: 0,
            salePricePerKilogram: 0,
            currency: "RUB" // KZT
        },
        {
            id: 2615,
            categoryId: 125,
            categoryName: "Черный металл",
            name: "15А",
            desc: "Проволока",
            pricePerTon: 9500,
            pricePerKilogram: 9.5,
            salePricePerTon: 0,
            salePricePerKilogram: 0,
            currency: "RUB" // KZT
        },
        {
            id: 1537,
            categoryId: 125,
            categoryName: "Черный металл",
            name: "16А",
            desc: "Стружка чугунная",
            pricePerTon: 5500,
            pricePerKilogram: 5.5,
            salePricePerTon: 0,
            salePricePerKilogram: 0,
            currency: "RUB" // KZT
        },
        //Медь и сплавы
        {
            id: 5263,
            categoryId: 2,
            categoryName: "Медь и сплавы",
            name: "Медь А 1-2",
            desc: "Микс",
            pricePerTon: 650000,
            pricePerKilogram: 650,
            salePricePerTon: 0,
            salePricePerKilogram: 0,
            currency: "RUB" // KZT
        },
        {
            id: 3625,
            categoryId: 2,
            categoryName: "Медь и сплавы",
            name: "Медь А 1-2",
            desc: "Медь луженая",
            pricePerTon: 580000,
            pricePerKilogram: 580,
            salePricePerTon: 0,
            salePricePerKilogram: 0,
            currency: "RUB" // KZT
        },
        {
            id: 7424,
            categoryId: 2,
            categoryName: "Медь и сплавы",
            name: "Медь А 1-3",
            desc: "Стружка",
            pricePerTon: 520000,
            pricePerKilogram: 520,
            salePricePerTon: 0,
            salePricePerKilogram: 0,
            currency: "RUB" // KZT
        },
        {
            id: 2411,
            categoryId: 2,
            categoryName: "Медь и сплавы",
            name: "Латунь А 5-1",
            desc: "Микс",
            pricePerTon: 340000,
            pricePerKilogram: 340,
            salePricePerTon: 0,
            salePricePerKilogram: 0,
            currency: "RUB" // KZT
        },
        {
            id: 6633,
            categoryId: 2,
            categoryName: "Медь и сплавы",
            name: "Латунь Б 5-2",
            desc: "Стружка",
            pricePerTon: 289000,
            pricePerKilogram: 289,
            salePricePerTon: 0,
            salePricePerKilogram: 0,
            currency: "RUB" // KZT
        },
        //Алюминий
        {
            id: 5266,
            categoryId: 52,
            categoryName: "Алюминий",
            name: "Алюминий А-1-1",
            desc: "Алюминий электротехнический",
            pricePerTon: 125000,
            pricePerKilogram: 125,
            salePricePerTon: 0,
            salePricePerKilogram: 0,
            currency: "RUB" // KZT
        },
        {
            id: 5248,
            categoryId: 52,
            categoryName: "Алюминий",
            name: "Алюминий А-1-1",
            desc: "Алюминий электротехнический",
            pricePerTon: 115000,
            pricePerKilogram: 115,
            salePricePerTon: 0,
            salePricePerKilogram: 0,
            currency: "RUB" // KZT
        },
        //Свинец
        {
            id: 1113,
            categoryId: 3,
            categoryName: "Свинец",
            name: "Свинец А-2",
            desc: "Кабель",
            pricePerTon: 78000,
            pricePerKilogram: 78,
            salePricePerTon: 0,
            salePricePerKilogram: 0,
            currency: "RUB" // KZT
        },
        {
            id: 1116,
            categoryId: 3,
            categoryName: "Свинец",
            name: "Свинец АЛ-2-2",
            desc: "Полипропилен",
            pricePerTon: 70000,
            pricePerKilogram: 70,
            salePricePerTon: 0,
            salePricePerKilogram: 0,
            currency: "RUB" // KZT
        },
        {
            id: 2136,
            categoryId: 3,
            categoryName: "Свинец",
            name: "Свинец АЛ-2-3",
            desc: "Гель, эбонит",
            pricePerTon: 65000,
            pricePerKilogram: 65,
            salePricePerTon: 0,
            salePricePerKilogram: 0,
            currency: "RUB" // KZT
        }
    ]
};
export default calcMock;
