function inputFileMultiply(loader) {
    // const dropBox = loader.querySelector('.file-loader__input-wrapper')
    const input = loader.querySelector("input");
    const label = loader.querySelector("label");
    const span = label.querySelector("label span");
    const files = loader.querySelector(".input-group--files-list");
    let fileList = new DataTransfer();
    const refreshFiles = () => {
        // Clear files
        Array.from(files.querySelectorAll(".input-group--files-item")).forEach((item) => {
            item.remove();
        });
        if (input.files.length > 0) {
            loader.classList.add("input-group--files_with-item");
            span.innerText = "Добавить";
        }
        else {
            loader.classList.remove("input-group--files_with-item");
            span.innerText = "Загрузить фотографии";
        }
        // Make all files
        Array.from(input.files).forEach((file) => {
            const el = document.createElement("div");
            el.classList.add("input-group--files-item");
            const img = document.createElement("img");
            img.src = URL.createObjectURL(file);
            img.onload = () => URL.revokeObjectURL(img.src);
            const elDelete = document.createElement("span");
            elDelete.classList.add("input-group--files-item-delete");
            elDelete.addEventListener("click", (e) => {
                const target = e.target;
                deleteFile(target.parentNode);
            });
            elDelete.title = "Удалить файл";
            el.appendChild(img);
            el.appendChild(elDelete);
            label.before(el);
        });
        console.log(input.files);
    };
    const deleteFile = (element) => {
        let dt = new DataTransfer();
        const index = getChildElementIndex(element);
        // Copy all besides deleted
        for (let i = 0; i <= input.files.length - 1; i++)
            if (i !== index)
                dt.items.add(input.files[i]);
        // Replace
        fileList = dt;
        input.files = dt.files;
        refreshFiles();
    };
    function getChildElementIndex(element) {
        return Array.prototype.indexOf.call(element.parentNode.querySelectorAll(".input-group--files-item"), element);
    }
    /*dropBox.addEventListener('drop', e => {
        input.files = e.dataTransfer.files

        refreshFiles()
        e.preventDefault()
    });*/
    input.addEventListener("change", (e) => {
        for (let i = 0; i <= input.files.length - 1; i++) {
            if (input.files[i]["size"] > 10485760)
                alert("Файл " + input.files[i]["name"] + " больше 10мб");
            else
                fileList.items.add(input.files[i]);
        }
        // Replace
        console.log(input.files);
        input.files = fileList.files;
        refreshFiles();
        e.preventDefault();
    });
}
const loaders = Array.from(document.querySelectorAll(".input-group--files"));
loaders.forEach((loader) => inputFileMultiply(loader));
