import Swiper from "swiper";
import { Navigation } from "swiper/modules";
class AchievementsSlider {
    constructor() {
        new Swiper('.achievements--slider', {
            modules: [Navigation],
            loop: false,
            grabCursor: true,
            slidesPerView: 'auto',
            spaceBetween: 8,
            navigation: {
                nextEl: '.button-slider__next',
                prevEl: '.button-slider__prev',
                disabledClass: 'disabled',
            },
        });
    }
}
export default AchievementsSlider;
