export const Timing = {
    easeInOutCirc: (x) => {
        return x < 0.5
            ? (1 - Math.sqrt(1 - Math.pow(2 * x, 2))) / 2
            : (Math.sqrt(1 - Math.pow(-2 * x + 2, 2)) + 1) / 2;
    },
    easeInOutCubic: (x) => {
        return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
    },
    easeOutSine: (x) => {
        return Math.sin((x * Math.PI) / 2);
    },
    linear: x => x,
};
export default class Animate {
    constructor(params) {
        //todo make Interface
        this.defaults = {
            type: 'default',
            draw: progress => progress,
            onEnd: () => { },
            timing: x => x,
            duration: 1000
        };
        this.options = { ...this.defaults, ...params };
        this.start();
    }
    start() {
        this.step = 0;
        this.direction = 1;
        this.second = 30;
        this.now = performance.now();
        this.endStep = this.second * (this.options.duration / 1000);
        this.rafId = requestAnimationFrame(this.animate.bind(this));
    }
    animate(time) {
        let timeFraction;
        if (this.options.type === 'default') {
            // timeFraction изменяется от 0 до 1
            timeFraction = (time - this.now) / this.options.duration;
            if (timeFraction > 1)
                timeFraction = 1;
        }
        else if (this.options.type === 'infinite') {
            // timeFraction изменяется от 0 до 1 и обратно
            this.step += this.direction;
            if (this.step === this.endStep || !this.step)
                this.direction = -this.direction;
            timeFraction = this.step / this.endStep;
        }
        // вычисление текущего состояния анимации
        let progress = (this.options.timing)
            ? this.options.timing(timeFraction)
            : timeFraction;
        // отрисовка
        this.options.draw(progress);
        if (this.options.type === 'default') {
            if (timeFraction < 1) {
                this.rafId = requestAnimationFrame(this.animate.bind(this));
            }
            else {
                this.onAnimateEnd();
            }
        }
        else if (this.options.type === 'infinite') {
            this.rafId = requestAnimationFrame(this.animate.bind(this));
        }
    }
    onAnimateEnd() {
        this.options.onEnd();
    }
    cancel() {
        cancelAnimationFrame(this.rafId);
        this.onAnimateEnd();
    }
}
function replaceArray(string, find, replace) {
    let replaceString = string;
    let regex;
    for (let i = 0; i < find.length; i++) {
        regex = new RegExp(find[i], "g");
        replaceString = replaceString.replace(regex, replace[i]);
    }
    return replaceString;
}
function animateSvg(props, delay = 5000) {
    const dot = [];
    const pathPlace = props.dots.map((i, index) => 'dot' + index);
    const element = document.querySelector(props.selector);
    new Animate({
        duration: typeof props.duration !== 'undefined' ? props.duration : delay,
        timing: Timing.linear,
        draw(progress) {
            props.dots.forEach((item, i) => {
                dot[i] = item.from + (item.to - item.from) * progress;
            });
            let path = replaceArray(props.path, pathPlace, dot);
            element.setAttribute('d', path);
        }
    });
}
//@ts-ignore
window.animateSvg = animateSvg;
