import YmapsLoader from "./ymaps-loader";
class PointsMap {
    constructor(container, options) {
        this.YMLoader = YmapsLoader.Instance;
        this.container = container;
        this.options = options;
        this.zoom =
            typeof this.options.zoom !== "undefined" ? this.options.zoom : 6;
        this.YMLoader.ready((ym) => {
            this.ym = ym;
            this.init();
        });
    }
    init() {
        this.map = new this.ym.YMap(this.container, {
            location: { center: this.options.start, zoom: this.zoom }
            // bounds: this.options.bounds
        });
        this.map.addChild(new this.ym.YMapDefaultSchemeLayer());
        this.map.addChild(new this.ym.YMapFeatureDataSource({ id: "points-source" }));
        this.map.addChild(new this.ym.YMapLayer({
            source: "points-source",
            type: "markers",
            zIndex: 1800
        }));
        this.map.addChild(new this.ym.YMapDefaultSchemeLayer({ theme: "light" }));
        this.selectedId = this.getDefaultSelected();
        this.addPoints();
    }
    setLocation(params) {
        if (!this.map)
            return;
        this.map.setLocation(params);
    }
    addPoints() {
        const marker = (feature) => new this.ym.YMapMarker({
            coordinates: feature.geometry.coordinates,
            source: "points-source",
            onClick: () => {
                this.onMarkClick(feature.id);
            }
        }, this.pinSingle(feature.id, feature.properties.selected).cloneNode(true));
        const cluster = (coordinates, features) => new this.ym.YMapMarker({ coordinates, source: "points-source" }, this.pinGroup(features.length, features).cloneNode(true));
        this.clusterer = new this.ym.YMapClusterer({
            method: this.ym.clusterByGrid({ gridSize: 64 }),
            features: this.createPoints(),
            onRender: (cl) => {
                this.onRender(cl);
            },
            marker,
            cluster
        });
        this.map.addChild(this.clusterer);
    }
    onRender(clusters) {
        if (typeof this.options.onRender !== "undefined")
            this.options.onRender(clusters);
    }
    onMarkClick(id) {
        this.selectedId = id;
        if (typeof this.options.onMarkerClick !== "undefined")
            this.options.onMarkerClick(id);
    }
    pinSingle(id, selected) {
        const imgDefault = document.createElement("img");
        imgDefault.className = "state-default";
        imgDefault.src = "/local/templates/exmetal/static/images/pin.svg";
        const imgSelected = document.createElement("img");
        imgSelected.className = "state-selected";
        imgSelected.src =
            "/local/templates/exmetal/static/images/pin_selected.svg";
        const span = document.createElement("span");
        const contentPin = document.createElement("div");
        contentPin.className = "pin-container";
        if (this.selectedId == id) {
            contentPin.classList.add("selected");
        }
        contentPin.dataset.pinId = id;
        contentPin.append(imgDefault);
        contentPin.append(imgSelected);
        contentPin.append(span);
        return contentPin;
    }
    pinGroup(count, features) {
        let ids = features.filter((item) => item.properties.selected === true);
        const pin = this.pinSingle("cluster", ids.length > 0);
        pin.querySelector("span").innerHTML = count;
        return pin;
    }
    getDefaultSelected() {
        return this.options.coordinates.filter((item) => {
            return item.SELECTED == true;
        })[0]["ID"];
    }
    createPoints() {
        return this.options.coordinates.map((item) => ({
            type: "Feature",
            id: item.ID,
            geometry: { coordinates: item.LAN_LAT },
            properties: { selected: item.SELECTED }
        }));
    }
}
export default PointsMap;
