import Swiper from "swiper";
class ValuesSlider {
    constructor() {
        new Swiper('.our-values--slider', {
            loop: false,
            grabCursor: true,
            slidesPerView: 'auto',
            spaceBetween: 12,
        });
    }
}
export default ValuesSlider;
