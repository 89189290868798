const slide__1 = {
    selector: "#slide__1 path",
    path: "M 0 265.995 V dot0 L 173.815 0 H 352 V 199.296 L dot1 265.995 H 0 Z M 0 400 V 334.56 L dot1 265.995 H 352 V 318.892 L 142.397 400 H 0 Z",
    dots: [
        { from: 199, to: 139 },
        { from: 200, to: 260 }
    ],
    morphStart: "M 0 265.995 V 199 L 173.815 0 H 352 V 199.296 L 200 265.995 H 0 Z M 0 400 V 334.56 L 200 265.995 H 352 V 318.892 L 142.397 400 H 0 Z",
    morphEnd: "M 0 265.995 V 139 L 173.815 0 H 352 V 199.296 L 260 265.995 H 0 Z M 0 400 V 334.56 L 260 265.995 H 352 V 318.892 L 142.397 400 H 0 Z"
};
const slide__2 = {
    duration: 5000,
    selector: "#slide__2 path",
    path: "M352 400H280.27L223.635 236.496V400H168.452L113.27 236.496V400H75.513L0 0H55.1826L113.27 236.496V0H165.548L223.635 236.496V0H307L352 182.5V400Z",
    dots: [{ from: 0, to: 1 }],
    morphStart: false,
    morphEnd: false
};
const slide__3 = {
    duration: 5000,
    selector: "#slide__3 path",
    path: "M0 200Vdot0L259.431 0H505L259.431 dot0V200H0ZM259.431 200L0 dot1V400H259.431Vdot1L505 200H259.431Z",
    dots: [
        { from: 97.5, to: 47.5 },
        { from: 297.5, to: 247.5 }
    ],
    morphStart: "M0 200V97.5L259.431 0H505L259.431 97.5V200H0ZM259.431 200L0 247.5V400H259.431V247.5L505 200H259.431Z",
    morphEnd: "M0 200V47.5L259.431 0H505L259.431 47.5V200H0ZM259.431 200L0 297.5V400H259.431V297.5L505 200H259.431Z"
};
export default [slide__1, slide__2, slide__3];
