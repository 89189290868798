import PointsMap from "./points-map";
function getPoints() {
    //@ts-ignore
    return POINTS;
}
function getCurrentPoint() {
    return getPoints().filter((items) => items.SELECTED === true)[0];
}
document.addEventListener("DOMContentLoaded", () => {
    const container = document.querySelector("#map");
    if (container) {
        const mainMap = new PointsMap(container, {
            //@ts-ignore
            coordinates: getPoints(),
            start: getCurrentPoint()["LAN_LAT"],
            zoom: 6
        });
    }
});
