import Swiper from "swiper";
import { Autoplay, EffectFade, Pagination } from "swiper/modules";
import slides from "./config";
import anime from "animejs/lib/anime.es.js";
import splitText from "../../../utils/split-text";
class HeroSlider {
    constructor() {
        this.delay = 4000;
        this.speed = 700;
        if (!document.querySelector(".hero-slider"))
            return;
        this.textSlider = new Swiper(".hero-slider--text", {
            modules: [Autoplay, Pagination, EffectFade],
            slidesPerView: 1,
            loop: false,
            simulateTouch: false,
            allowTouchMove: false,
            speed: 50,
            effect: "fade",
            fadeEffect: {
                crossFade: true
            },
            autoplay: {
                delay: this.delay,
                disableOnInteraction: false
            },
            pagination: {
                el: document.querySelector(".hero-slider .swiper-pagination"),
                clickable: true,
                bulletActiveClass: "progress-bar--active",
                renderBullet: (index, className) => {
                    return `<div class="progress-bar ${className}"><div class="progress-bar__line"></div></div>`;
                }
            },
            on: {
                beforeInit: () => {
                    const item = document.querySelector(".hero-slider");
                    if (item)
                        item.style["--autoplay-delay"] = `${this.delay}ms`;
                },
                init: (swiper) => {
                    swiper.autoplay.stop();
                },
                afterInit: () => {
                    Array.from(document.querySelectorAll(".hero-slider--text__main")).forEach((item) => splitText(item));
                },
                slideChangeTransitionEnd: () => {
                    setTimeout(() => this.slideAnimateStart(), 50);
                }
            }
        });
        this.vectorSlider = new Swiper(".hero-slider--vectors", {
            loop: false,
            simulateTouch: false,
            allowTouchMove: false,
            spaceBetween: 10,
            speed: 300,
            breakpoints: {
                1140: {
                    spaceBetween: 200
                }
            }
        });
        this.textSlider.on("slideChangeTransitionStart", (swiper) => {
            this.vectorSlider.slideTo(swiper.activeIndex);
        });
        document
            .querySelector(".hero-slider--nav__btn .button")
            .addEventListener("click", this.slideNext.bind(this), {
            passive: true
        });
    }
    start() {
        if (!document.querySelector(".hero-slider"))
            return;
        this.textSlider.autoplay.start();
        this.slideAnimateStart();
    }
    slideNext() {
        const slidesLength = this.textSlider.slides.length - 1;
        const nextIndex = slidesLength < this.textSlider.realIndex + 1
            ? 0
            : this.textSlider.realIndex + 1;
        this.textSlider.slideTo(nextIndex);
    }
    slideAnimateStart() {
        this.progressAnimateStart();
        this.textAnimateStart();
        this.vectorAnimateStart();
    }
    progressAnimateStart() {
        Array.from(document.querySelectorAll(".hero-slider .progress-bar")).forEach((item) => item.classList.remove("progress-bar--running"));
        document
            .querySelector(".progress-bar--active")
            .classList.add("progress-bar--running");
    }
    textAnimateStart() {
        this.timeline = anime.timeline({
            targets: ".swiper-slide-active .animated-text",
            duration: 250,
            easing: "easeInSine",
            delay: (el, i) => i * 50
        });
        this.timeline.add({ translateY: "0%", endDelay: this.delay - 1200 });
        this.timeline.add({ translateY: "-105%" });
        this.timeline.add({ translateY: "100%", duration: 0 });
    }
    vectorAnimateStart() {
        if (isNaN(this.vectorSlider.progress))
            return;
        const slide = this.vectorSlider.slides[this.textSlider.activeIndex];
        //@ts-ignore //todo delete
        animateSvg(slides[this.textSlider.activeIndex], this.delay);
        /*const current = slides[this.textSlider.activeIndex];
        if (current.morphStart)
            anime({
                targets: document.querySelector(current.selector),

                d: [
                    {
                        value: current?.morphStart
                    },
                    {
                        value: current?.morphEnd
                    }
                ],
                duration: this.delay,
                direction: "normal",
                easing: "linear",
                elasticity: 100
            });*/
        anime({
            targets: slide.querySelector("img"),
            translateX: [0, "-20%"],
            duration: 15000,
            easing: "linear"
        });
    }
}
export const heroSlider = new HeroSlider();
