import Animate, { Timing } from "../utils/animate";
class Preloader {
    constructor() {
        this.container = document.querySelector('.preloader');
        if (!this.container)
            return;
    }
    start() {
        const preloaderVector = document.querySelector('.preloader path');
        this.animate = new Animate({
            type: 'infinite',
            duration: 2000,
            timing: Timing.easeInOutCubic,
            draw(progress) {
                const dotA = 70 + 210 * progress;
                preloaderVector.setAttribute('d', `M 0 265.995 V 199.296 L 173.815 0 H 352 V 199.296 L ${dotA} 265.995 H 0 Z M 0 400 V 334.56 L ${dotA} 265.995 H 352 V 318.892 L 142.397 400 H 0 Z`);
            }
        });
    }
    end() {
        this.container.classList.add('done');
        this.animate.cancel();
    }
}
export default new Preloader();
