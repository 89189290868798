//@ts-ignore
import * as ymaps3 from "ymaps3";
class YmapsLoader {
    constructor() {
        this.inited = false;
    }
    ready(callback) {
        if (this.inited) {
            return callback(this);
        }
        else {
            this.load(callback);
            return this;
        }
    }
    load(callback) {
        //@ts-ignore
        ymaps3.ready
            .then(() => ymaps3.import("@yandex/ymaps3-clusterer@0.0.1"))
            .then((cluster) => {
            this.ymaps3 = ymaps3;
            this.ymCluster = cluster;
            this.YMap = ymaps3.YMap;
            this.YMapDefaultSchemeLayer = ymaps3.YMapDefaultSchemeLayer;
            this.YMapFeatureDataSource = ymaps3.YMapFeatureDataSource;
            this.YMapLayer = ymaps3.YMapLayer;
            this.YMapMarker = ymaps3.YMapMarker;
            this.YMapClusterer = cluster.YMapClusterer;
            this.clusterByGrid = cluster.clusterByGrid;
            this.inited = true;
            callback(this);
        });
    }
    static get Instance() {
        // Do you need arguments? Make it a regular static method instead.
        return this._instance || (this._instance = new this());
    }
}
export default YmapsLoader;
