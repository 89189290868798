import Swiper from "swiper";
import { Pagination } from "swiper/modules";
class AdvantagesSlider {
    constructor() {
        new Swiper('.advantages--slider', {
            modules: [Pagination],
            loop: false,
            grabCursor: true,
            slidesPerView: 'auto',
            spaceBetween: 24,
            pagination: {
                el: document.querySelector('.advantages--slider .swiper-pagination'),
                clickable: true,
                bulletActiveClass: 'progress-bar--active',
                renderBullet: (index, className) => {
                    return `<div class="progress-bar ${className}"><div class="progress-bar__line"></div></div>`;
                }
            },
        });
    }
}
export default AdvantagesSlider;
