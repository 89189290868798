document.addEventListener("DOMContentLoaded", () => {
    setTimeout(() => {
        const tooltip = document.querySelector('.tooltip[data-flag="show"]');
        const pointContainer = document
            .querySelector(".header-top__place--name")
            .getBoundingClientRect();
        tooltip.style.left = pointContainer.left + "px";
        tooltip.style.top = 34 + "px";
        tooltip.classList.add("tooltip_opened");
        tooltip
            .querySelector(".tooltip--close")
            .addEventListener("click", () => tooltip.classList.remove("tooltip_opened"));
    }, 2000);
});
