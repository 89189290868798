import Popup from "../popup";
import PointsBase from "../points-base";
import { pointsPopupTemplate } from "./points-popup-template";
import { bitrixComponent } from "../../utils/bitrix-component";
class PointsPopup extends PointsBase {
    constructor() {
        const container = document.querySelector(".popup.popup_points");
        const mapContainer = document.querySelector(".popup--map-wrapper");
        super(container, mapContainer, pointsPopupTemplate);
        // Init popup
        this.popup = new Popup(container);
        /* Open popup */
        Array.from(document.querySelectorAll(".js-open-popup-points")).forEach((item) => {
            item.onclick = () => this.popup.open();
        });
        /* Save */
        container
            .querySelector(".js-save-point-choice")
            .addEventListener("click", () => {
            this.savePoint();
        });
    }
    savePoint() {
        const inputItem = document.querySelector('input[name="points_popup"]:checked');
        if (!inputItem) {
            alert("Выберите пункт приема");
            return;
        }
        const pointData = this.getPointById(inputItem.value);
        document.querySelector(".header-top__city-name").innerHTML =
            pointData.LOCALITIES_NAME;
        document.querySelector(".header-top__place--name").innerHTML =
            pointData.NAME;
        let fd = new FormData();
        fd.append("pointId", pointData.ID);
        bitrixComponent("exmetal:api", "setPoint", fd, "class").then((response) => {
            if (response.status === "success") {
                this.popup.close();
                window.location.reload();
            }
            else if (response.status === "error") {
            }
        });
    }
}
export default PointsPopup;
